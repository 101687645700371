import React from 'react';
import { useEffect, useState } from 'react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import { debounce } from 'lodash';
import { CustButton, CustLoader } from 'src/reusable';
import { getInputPrefix, getOutputPrefix } from 'src/utils/common';
import {toast, ToastContainer} from 'react-toastify';
import CIcon from '@coreui/icons-react'
import { ConsoleWriter } from 'istanbul-lib-report';
import moment from "moment";
const tmpObj = {
  earning: {},
  deduction: {},
};
const CtcGenerate = props => {
  const api = new API();
  const {
    employeeDetail,
    setIsViewSalaryOpen,
    getAllEmployeeSalary
  } = props;
  const [employeeSalaryDetail, setEmployeeSalaryDetails] = useState({
    bonus: {},
    earnings: [],
    deductions: [],
    ctc: {},
    gross: {},
    total_employee: {},
    total_employer: {},
    total_ctc: {},
    take_home: {},
    emp_id: '',
  });
  const [salaryComponents, setSalaryComponents] = useState();
  const [earningArr, setEarningArr] = useState([]);
  const [deductionArr, setDeductionArr] = useState();
  const [selectedEarningArr, setSelectedEarningaArr] = useState([]);
  const [selectedDeductionArr, setSelsctedDeductionArr] = useState([]);
  const [ctc, setCtc] = useState(0);
  const [callCount, setCallCount] = useState(0);
  const [includeBonus, setIncludeBonus] = useState(false);
  const [payDays, setPayDays] = useState(0);
  const [absentDays, setAbsentDays] = useState(0);
  const [presentDays, setPresentDays] = useState(0);
  const [enableButton, setEnableButton] = useState(false);
  const [isRecalculatingSalary, setIsRecalculatingSalary] = useState(false);

  const getSalaryComponents = async () => {
    let result = await api.get(config.salaryComponents);
    return result && result.code === 200 && result.data;
  };
  useEffect(() => {
    const fetchSalaryComponents = async () => {
      const templateData = await getSalaryComponents();
      setEarningArr(templateData?.earnings || []);
      setDeductionArr(templateData?.deductions || []);
    };
    fetchSalaryComponents();
  }, []);

  useEffect(() => {
    debouncedReCalculate(null);
  }, [callCount]);

  useEffect(() => {
    // create array for selected salary components name
    if (employeeSalaryDetail?.earnings?.length) {
      const eArr = [];
      employeeSalaryDetail?.earnings?.forEach(item => {
        eArr.push(item.name);
      });
      setSelectedEarningaArr(eArr);
    }
    if (employeeSalaryDetail?.deductions?.length) {
      const dArr = [];
      employeeSalaryDetail?.deductions?.forEach(item => {
        dArr.push(item.name);
      });
      setSelsctedDeductionArr(dArr);
    }
  }, [employeeSalaryDetail]);

  useEffect(() => {
    setEmployeeSalaryDetails({
      bonus: employeeDetail?.bonus,
      earnings: employeeDetail?.earnings,
      deductions: employeeDetail?.deductions,
      ctc: employeeDetail?.ctc_A,
      gross: employeeDetail?.gross,
      total_employee: employeeDetail?.total_employee_contribution,
      total_employer: employeeDetail?.total_employer_contribution,
      total_ctc: employeeDetail?.total_ctc,
      take_home: employeeDetail?.take_home,
      monthly_gross: employeeDetail?.ctc,
      arrear: employeeDetail?.arrear,
      emp_id: employeeDetail?.emp_id || '',
    });
    setCtc(employeeDetail?.ctc);
    setPayDays(employeeDetail?.pay_days);
    setAbsentDays(employeeDetail?.absent_days);
    setPresentDays(employeeDetail?.present_days);
  }, [employeeDetail])

  const handleOnComponenetAdd = (comp, name) => {
    let selectedComponent;
    let eDetails = employeeSalaryDetail?.earnings
      ? employeeSalaryDetail.earnings
      : [];
    let dDetails =
      employeeSalaryDetail && employeeSalaryDetail?.deductions
        ? employeeSalaryDetail.deductions
        : [];
    if (comp === 'earning') {
      let earningsArr = [...earningArr];
      for (let val of earningsArr) {
        if (val.name === name) {
          selectedComponent = val;
        }
      }
      eDetails.push(selectedComponent);
    } else {
      let deductionsArr = [...deductionArr];
      for (let val of deductionsArr) {
        if (val.name === name) {
          selectedComponent = val;
        }
      }
      dDetails.push(selectedComponent);
    }

    setEmployeeSalaryDetails({
      ...employeeSalaryDetail,
      earnings: eDetails,
      deductions: dDetails,
    });
    setCallCount(callCount + 1);
  };

  const handleOnComponentRemove = (e, item, index, type) => {
    const { earnings, deductions } = employeeSalaryDetail;
    if (type === 'earning') {
      earnings.splice(index, 1);
      tmpObj.earning[item.name] = '';
    } else if (type === 'deduction') {
      deductions.splice(index, 1);
      tmpObj.deduction[item.name] = '';
    }
    setEmployeeSalaryDetails({ ...employeeSalaryDetail, earnings, deductions });
    setCallCount(callCount + 1);
  };

 

 

  const handleOnCtcReCalculate = async dataA => {
    setIsRecalculatingSalary(true);
    const { earnings, deductions,arrear  } = employeeSalaryDetail;
    earnings.forEach(item => {
      if (tmpObj?.earning?.[item.name])
        item.value = tmpObj?.earning?.[item.name];
      return item;
    });
    deductions.forEach(item => {
      if (tmpObj?.deduction?.[item.name])
        item.value = tmpObj?.deduction?.[item.name];
      return item;
    });
    const updatedDetails = { ...employeeSalaryDetail, earnings, deductions };
    const data = {
      ctc: ctc || employeeSalaryDetail?.total_ctc?.monthly || 0,
      earnings: JSON.stringify(updatedDetails?.earnings),
      deductions: JSON.stringify(updatedDetails?.deductions),
      bonus: JSON.stringify(updatedDetails?.bonus),
      is_include_bonus: (updatedDetails?.bonus!={})?1:0,
      query_type: "salary_calculation",
      present: payDays,
      arrear: arrear,
      month: employeeDetail.month ? employeeDetail.month : moment().format("YYYY-MM"),
      emp_id: employeeDetail?.emp_id || '',
    };
    console.log('Searching for:', data);
    const result = await api.post(config.updateCalculatedCtc, data);
    if (result && result.code === 200) {
      console.log('Successfully calculated', result);
      setEmployeeSalaryDetails(result.data);
      setEnableButton(true);
      if(result.message !== "CTC Calculated Successfully"){
        toast.error(result?.message);
        setEnableButton(false);
      }
    } else {
      console.log(result?.message || result);
    }
    setIsRecalculatingSalary(false);
  };

  const debouncedReCalculate = debounce(handleOnCtcReCalculate, 100);

  const handleOnSalaryChange = async (e, item, index, type) => {
    const { name, value } = e.target;
    tmpObj[type][item.name] = value;
    setCallCount(callCount + 1);
  };

  const isComponenetDisabled = (item, type) => {
    if (type === 'earning') {
      return selectedEarningArr.indexOf(item?.name) !== -1;
    }
    if (type === 'deduction') {
      return selectedDeductionArr.indexOf(item?.name) !== -1;
    }
  };

  const saveRecalculatedSalary = async() => {
    let data = {
      id: employeeDetail.emp_id,
      month: employeeDetail.month,
      bonus: JSON.stringify(employeeSalaryDetail.bonus),
      earnings: JSON.stringify(employeeSalaryDetail.earnings),
      deductions: JSON.stringify(employeeSalaryDetail.deductions),
      ctc: JSON.stringify(employeeSalaryDetail.ctc),
      gross: JSON.stringify(employeeSalaryDetail.gross),
      total_employee: JSON.stringify(employeeSalaryDetail.total_employee),
      total_employer: JSON.stringify(employeeSalaryDetail.total_employer),
      total_ctc: JSON.stringify(employeeSalaryDetail.total_ctc),
      take_home: JSON.stringify(employeeSalaryDetail.take_home),
    }
    let result = await api.post(config.updateEmployeeSalary, data);
    if(result && result.code === 200){
      toast.success(result?.message);
      getAllEmployeeSalary();
      setIsViewSalaryOpen(false);
    }else{
      console.log(result?.message || result);
    }
  }

  const renderSalaryComponentsList = listType => {
    let dataList = earningArr;
    if (listType === 'deduction') {
      dataList = deductionArr;
    }
    return (
      dataList &&
      dataList.map((item, index) => {
        let onBtnClick = handleOnComponenetAdd;
        const isDisabled = isComponenetDisabled(item, listType);
        return (
          <div
            key={index}
            className="accordion-body accordion-salary-component"
          >
            <button
              className="btn add_new_template_sidebar_button w-100 add-comp-btn"
              style={{ display: 'flex' }}
              onClick={() => onBtnClick(listType, item.name)}
              disabled={isDisabled}
            >
              {item.payslip_name}
              <span>
                <i className="fa fa-plus add_new_template_sidebar_button_icon"></i>
              </span>
            </button>
          </div>
        );
      })
    );
  };
  return (
    <div>
      <div className="row">
        <div className="col-sm-3">
          <div className="">
            <div className="ml-4 mt-3">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item abd">
                  <h5 className="accordion-header" id="headingOne">
                    <button
                      className="text-left accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <b>Earning</b>
                    </button>
                  </h5>

                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse collapsed "
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    {renderSalaryComponentsList('earning')}
                  </div>
                </div>
                <div className="accordion-item">
                  <h5 className="accordion-header" id="headingFour">
                    <button
                      className="text-left accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="true"
                      aria-controls="collapseFour"
                    >
                      <b>Deductions</b>
                    </button>
                  </h5>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    {renderSalaryComponentsList('deduction')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-9">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" style={{ color: 'darkblue' }}>
                  <b>Gross / CTC</b>
                </th>
                <th>
                  {' '}
                  <input
                    type="number"
                    name="monthly_ctc"
                    className="form-control col-lg-6"
                    value={ctc}
                    placeholder="Enter Monthly CTC"
                    disabled
                  />
                </th>
                <th scope="col">Pay Days : &nbsp;&nbsp;{payDays}</th>
                <th scope="col">Present Days : &nbsp;&nbsp;{presentDays}</th>
                <th scope="col">Absent Days : &nbsp;&nbsp;{absentDays}</th>
              </tr>
            </thead>
          </table>
          {isRecalculatingSalary && <CustLoader size="md" isShow={isRecalculatingSalary} />}

          {ctc > 0 && (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Salary Components</th>
                  <th scope="col">Calculation Type</th>
                  <th scope="col">Calculated Amount <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" title="Attendance based calculated amount"></i>
         </th>
                  <th scope="col">Monthly Amount  <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" title="Total monthly amount"></i>
          </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <h4>Earnings</h4>
                  </th>
                </tr>
                {employeeSalaryDetail?.earnings &&
                  employeeSalaryDetail?.earnings?.map((item, index) => (
                    <tr key={item.name}>
                      <td>{item.payslip_name}</td>
                      <td>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text input-prefix-icon"
                              id="basic-addon1"
                            >
                              {getInputPrefix(item.value_in)}
                            </span>
                          </div>
                          <input
                            type="number"
                            name="earning"
                            value={
                              tmpObj?.earning?.[item.name] &&
                              tmpObj?.earning?.[item.name] !== ''
                                ? tmpObj?.earning?.[item.name]
                                : item.value
                            }
                            onChange={e =>
                              handleOnSalaryChange(e, item, index, 'earning')
                            }
                            className="form-control"
                            placeholder=""
                          />
                          {item.value_in=="percentage" && <>
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text input-prefix-icon"
                              id="basic-addon1"
                            >
                              {getOutputPrefix(item.name,item.value_in)}
                            </span>
                          </div>
                          </>}
                        </div>
                      </td>
                      <td>{item.real}</td>
                      <td>
                        {item.monthly}
                        {item.name !== 'basic' && (
                          <i
                            className={`fa fa-trash text-danger cursor`}
                            aria-hidden="true"
                            style={{ float: 'right' }}
                            onClick={e =>
                              handleOnComponentRemove(e, item, index, 'earning')
                            }
                          ></i>
                        )}
                      </td>
                    </tr>
                  ))}
                <tr>
                  <th>
                    <h6 style={{ color: 'green' }}>
                      <b>GROSS</b>
                    </h6>
                  </th>
                  <th></th>
                  <th>
                    <h6 style={{ color: 'green' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.gross?.real}
                      </b>
                    </h6>
                  </th>
                  <th>
                    <h6 style={{ color: 'green' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.gross?.monthly}
                      </b>
                    </h6>
                  </th>
                </tr>
                <tr>
                  <th>
                    <h4>Deductions</h4>
                  </th>
                </tr>
                <tr>
                  <td style={{ color: 'darkblue' }}>
                    <b>Employee`s Contribution</b>
                  </td>
                </tr>
                {employeeSalaryDetail?.deductions &&
                  employeeSalaryDetail?.deductions.map((item, index) => (
                    <tr key={index}>
                      {item.name.match(/employee/i) && (
                        <>
                          <td>{item.payslip_name}</td>
                          <td>
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text input-prefix-icon"
                                  id="basic-addon1"
                                >
                                  {getInputPrefix(item.value_in)}
                                </span>
                              </div>
                              <input
                                type="number"
                                name="deduction"
                                value={
                                  tmpObj?.deduction?.[item.name] &&
                                  tmpObj?.deduction?.[item.name] !== ''
                                    ? tmpObj?.deduction?.[item.name]
                                    : item.value
                                }
                                onChange={e =>
                                  handleOnSalaryChange(
                                    e,
                                    item,
                                    index,
                                    'deduction',
                                  )
                                }
                                className="form-control"
                                placeholder=""
                              />
                              {item.value_in=="percentage" && <>
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text input-prefix-icon"
                              id="basic-addon1"
                            >
                              {getOutputPrefix(item.name,item.value_in)}
                            </span>
                          </div>
                          </>}
                            </div>
                          </td>
                          <td>{item.real}</td>
                          <td>
                            {item.monthly}
                            <i
                              className={`fa fa-trash text-danger cursor`}
                              aria-hidden="true"
                              style={{ float: 'right' }}
                              onClick={e =>
                                handleOnComponentRemove(
                                  e,
                                  item,
                                  index,
                                  'deduction',
                                )
                              }
                            ></i>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                <tr>
                  <th>
                    <h6 style={{ color: 'black' }}>
                      <b>Total Employee Contribution</b>
                    </h6>
                  </th>
                  <th></th>
                  <th>
                    <h6 style={{ color: 'black' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.total_employee?.real}
                      </b>
                    </h6>
                  </th>
                  <th>
                    <h6 style={{ color: 'black' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.total_employee?.monthly}
                      </b>
                    </h6>
                  </th>
                </tr>
                <tr>
                  <td style={{ color: 'darkblue' }}>
                    <b>Employer`s Contribution</b>
                  </td>
                </tr>
                {employeeSalaryDetail?.deductions &&
                  employeeSalaryDetail.deductions.map((item, index) => (
                    <tr key={index}>
                      {(item.name.match(/employer/i) ||
                        item.name.match(/gmc/i)) && (
                        <>
                          <td>{item.payslip_name}</td>
                          <td>
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text input-prefix-icon"
                                  id="basic-addon1"
                                >
                                  {getInputPrefix(item.value_in)}
                                </span>
                              </div>
                              <input
                                type="number"
                                name="pf"
                                value={
                                  tmpObj?.deduction?.[item.name] &&
                                  tmpObj?.deduction?.[item.name] !== ''
                                    ? tmpObj?.deduction?.[item.name]
                                    : item.value
                                }
                                onChange={e =>
                                  handleOnSalaryChange(
                                    e,
                                    item,
                                    index,
                                    'deduction',
                                  )
                                }
                                className="form-control"
                                placeholder=""
                              />
                              {item.value_in=="percentage" && <>
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text input-prefix-icon"
                              id="basic-addon1"
                            >
                              {getOutputPrefix(item.name,item.value_in)}
                            </span>
                          </div>
                          </>}
                            </div>
                          </td>
                          <td>{item.real}</td>
                          <td>{item.monthly}
                          <i
                              className={`fa fa-trash text-danger cursor`}
                              aria-hidden="true"
                              style={{ float: 'right' }}
                              onClick={e =>
                                handleOnComponentRemove(
                                  e,
                                  item,
                                  index,
                                  'deduction',
                                )
                              }
                            ></i>
                            </td>
                        </>
                      )}
                    </tr>
                  ))}
                <tr>
                  <th>
                    <h6 style={{ color: 'black' }}>
                      <b>Total Employer Contribution</b>
                    </h6>
                  </th>
                  <th></th>
                  <th>
                    <h6 style={{ color: 'black' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.total_employer?.real}
                      </b>
                    </h6>
                  </th>
                  <th>
                    <h6 style={{ color: 'black' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.total_employer?.monthly}
                      </b>
                    </h6>
                  </th>
                </tr>
                <tr>
                  <td style={{ color: 'darkblue' }}>
                    <b>Other Deductions</b>
                  </td>
                </tr>
                {employeeSalaryDetail?.deductions &&
                  employeeSalaryDetail?.deductions.map((item, index) => (
                    <tr key={index}>
                      {!(item.name.match(/employee/i) || item.name.match(/employer/i)) && (
                        <>
                          <td>{item.payslip_name}</td>
                          <td>
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text input-prefix-icon"
                                  id="basic-addon1"
                                >
                                  {getInputPrefix(item.value_in)}
                                </span>
                              </div>
                              <input
                                type="number"
                                name="deduction"
                                value={
                                  tmpObj?.deduction?.[item.name] &&
                                  tmpObj?.deduction?.[item.name] !== ''
                                    ? tmpObj?.deduction?.[item.name]
                                    : item.value
                                }
                                onChange={e =>
                                  handleOnSalaryChange(
                                    e,
                                    item,
                                    index,
                                    'deduction',
                                  )
                                }
                                className="form-control"
                                placeholder=""
                              />
                              {item.value_in=="percentage" && <>
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text input-prefix-icon"
                              id="basic-addon1"
                            >
                              {getOutputPrefix(item.name,item.value_in)}
                            </span>
                          </div>
                          </>}
                            </div>
                          </td>
                          <td>{item.real}</td>
                          <td>
                            {item.monthly}
                            <i
                              className={`fa fa-trash text-danger cursor`}
                              aria-hidden="true"
                              style={{ float: 'right' }}
                              onClick={e =>
                                handleOnComponentRemove(
                                  e,
                                  item,
                                  index,
                                  'deduction',
                                )
                              }
                            ></i>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                <tr></tr>
                <tr>
                  <th>
                    <h6 style={{ color: 'RebeccaPurple' }}>
                      <b>CTC A</b>
                    </h6>
                  </th>
                  <th></th>
                  <th>
                    <h6 style={{ color: 'RebeccaPurple' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.ctc?.real}
                      </b>
                    </h6>
                  </th>
                  <th>
                    <h6 style={{ color: 'RebeccaPurple' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.ctc?.monthly}
                      </b>
                    </h6>
                  </th>
                </tr>
                <tr>
                  <th>
                    <h6 style={{ color: 'DarkSlateBlue' }}>
                      <b>BONUS</b>
                    </h6>
                  </th>
                  <th></th>
                  <th>
                    <h6 style={{ color: 'DarkSlateBlue' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.bonus?.real}
                      </b>
                    </h6>
                  </th>
                  <th>
                    <h6 style={{ color: 'DarkSlateBlue' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.bonus?.monthly}
                      </b>
                    </h6>
                  </th>
                </tr>
                <tr style={{ background: '#3f4f8d' }}>
                  <th>
                    <h5 style={{ color: 'white' }}>
                      <b>TOTAL CTC</b>
                    </h5>
                  </th>
                  <th></th>
                  <th>
                    <h5 style={{ color: 'white' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.total_ctc?.real}
                      </b>
                    </h5>
                  </th>
                  <th>
                    <h5 style={{ color: 'white' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.total_ctc?.monthly}
                      </b>
                    </h5>
                  </th>
                </tr>
                <tr style={{ background: '#eb7c45' }}>
                  <th>
                    <h5 style={{ color: 'black' }}>
                      <b>TAKE HOME</b>
                    </h5>
                  </th>
                  <th></th>
                  <th>
                    <h5 style={{ color: 'black' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.take_home?.real}
                      </b>
                    </h5>
                  </th>
                  <th>
                    <h5 style={{ color: 'black' }}>
                      <b>
                        <i className="fa fa-inr" aria-hidden="true"></i>{' '}
                        {employeeSalaryDetail?.take_home?.monthly}
                      </b>
                    </h5>
                  </th>
                </tr>
              </tbody>
            </table>
          )}
          {/* <div className="actions text-left pl-5">
            <button className="btn btn-primary ml-5" onClick={addEmployee}>
              Save
            </button>
            <button className="btn btn-outline-dark ml-2">Cancel</button>
          </div> */}
          {enableButton && (<>
          <div className="text-right">
            <CustButton
              type="button"
              name="pdf-view"
              text="Save"
              color="primary"
              onClick={() => saveRecalculatedSalary()}
              className="btn-loading btn-ladda mr-3"
              icon="fa-paper-plane"
            />
            {/* <CustButton
                    type="submit"
                    name="save-ctc"
                    text="Submit"
                    color="success"
                    onClick={() => handleViewOfferLetter()}
                    className="btn-loading btn-ladda"
                    icon="fa-paper-plane"
                    isDisabled={false}
                    isLoading={false}
                  /> */}
          </div>
          </>)}
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
};

export default CtcGenerate;
