import React from 'react';
import { convertDate } from 'src/utils/common';
import { NoRecords } from 'src/reusable';
import { CDataTable } from '@coreui/react';

const TourAppliedList = props => {
  const { data, type, renderDataStatus } = props;
  const fields = [
    {
      label: 'S.No.',
      key: 's_no',
      sorter: true,
      filter: true,
    },
    {
      label: 'Date From',
      key: 'applied_from',
      sorter: true,
      filter: true,
    },
    {
      label: 'Date To',
      key: 'applied_to',
      sorter: false,
      filter: false,
    },
    {
      label: 'Apply For',
      key: 'apply_for',
      sorter: false,
      filter: true,
    },
    {
      label: 'Visiting Destination',
      key: 'visiting_destination',
      sorter: false,
      filter: false,
    },
    {
      label: 'Reason',
      key: 'reason',
      sorter: false,
      filter: true,
    },
    {
      label: 'Comment',
      key: 'comment',
      sorter: false,
      filter: true,
    },
    {
      label: 'Approval Status',
      key: 'status',
      sorter: false,
      filter: false,
    },
    {
      label: 'Created Date',
      key: 'created_at',
      sorter: true,
      filter: true,
    },
  ];
  return (
    <CDataTable
    items={data}
    fields={fields}
    tableFilter={{label: 'Filter:', placeholder: 'Search'}}
    itemsPerPageSelect
    itemsPerPage={5}
    hover
    sorter
    pagination
    scopedSlots={{
      status: item => <td>{renderDataStatus(item, type)}</td>,
      applied_from: item => (
        <td>{convertDate(item.applied_from, 'DD-MM-YYYY')}</td>
      ),
      applied_to: item => (
        <td>{convertDate(item.applied_to, 'DD-MM-YYYY')}</td>
      ),
      created_at: item => (
        <td>{convertDate(item.created_at, 'DD-MM-YYYY')}</td>
      ),
      s_no: (item, index) => (
        <td>
          <b>{index + 1}</b>
        </td>
      ),
      reason: (item, index) => (
        <td>
          {item.reason || ''}
        </td>
      ),
      comment: (item, index) => (
        <td>
          {item?.is_approve_status == 1 ? item?.approval_comment : item?.rejection_comment}
        </td>
      ),
      apply_for: (item, index) => (
        <td>
          {item?.apply_for === "full_day" ? "Full Day" 
          : item?.apply_for === "first_half_day" ? "First Half Day"
          : item?.apply_for === "second_half_day" ? "Second Half Day" : item?.apply_for}
        </td>
      ),
    }}
  />
  );
};

export default TourAppliedList;
