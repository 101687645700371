import React, { useState, useEffect } from 'react';
import '../views/ATTENDANCE/components/check_In_Check_Out.css';
import { connect } from 'react-redux';
import Webcam from 'react-webcam';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';
import swal from 'sweetalert';
import appConstants from 'src/core/configs/Constants.config';
import { CModal, CModalBody, CModalFooter, CButton } from '@coreui/react';
import {
  attendanceActions,
  attendanceSelector,
} from 'src/core/resource/attendance';
import { getLocalDataAsObject } from '../utils/CoustomStorage';
import { config } from '../utils/apiUrl';
import API from 'src/utils/apiCalling';

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'user',
};
const { get } = require('lodash');

const CheckInCheckOut = props => {
  const api = new API();
  const user = getLocalDataAsObject('user');
  const {
    navigateToGetAttendanceBasicDetailsRequest,
    getAttendanceBasicDetailsRes,
    getAttendanceBasicDetailsResError,
    isGetAttendanceBasicDetailsPending,
  } = props;
  const [timer, setTimer] = useState('00:00:00');
  const [loggedUserInfo, setLoggedUserInfo] = useState(
    user?.length ? user[0] : null,
  );
  const workingFrom = loggedUserInfo?.working_from;
  const [captureImage, setCaptureImage] = useState('');
  const [isCamera, setIsCamera] = useState(true);
  const [isWebCamAvailable, setIsWebCamAvailable] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [isOpen1, setIsOpen1] = useState(false);
  const [srtBrk, setSrtBrk] = useState();
  const [lngBrk, setLngBrk] = useState();
  const [otherBrk, setOtherBrk] = useState();
  const [firstCheckStatus, setFirstCheckStatus] = useState();
  const [atndType, setAtndType] = useState();
  const [attendanceType, setAttendanceType] = useState('');
  const [checkInStatus, setCheckInStatus] = useState('');
  const [lastCaptureImage, setLastCaptureImage] = useState('');
  const [otherbrTime, setOtherbrTime] = useState('');
  const [otherBreakReason, setOtherBreakReason] = useState('');
  const [isLoader, setIsLoader] = useState(false);
  const permission = parseFloat(sessionStorage?.attendanceModal);
  useEffect(() => {
    checkMediaAndAccess();
  }, []);
  const checkMediaAndAccess = () => {
    navigator.mediaDevices.enumerateDevices().then(devices =>
      devices.forEach(device => {
        if (device.kind === 'videoinput') {
          setIsWebCamAvailable(true);
          setCaptureImage('');
        }
      }),
    );
    navigator.getMedia =
      navigator.getUserMedia || // use the proper vendor prefix
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;

    navigator.mediaDevices.getUserMedia(
      { video: true },
      function() {
        setIsCamera(true);
      },
      function(err) {
        setIsCamera(false);
        toast.warning('webcam is not available');
      },
    );
  };
  const webcamRef = React.useRef(null);
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    // console.log('image',imageSrc)
    setCaptureImage(imageSrc);
  }, [webcamRef]);
  useEffect(() => {
    if (!isGetAttendanceBasicDetailsPending) {
      if (getAttendanceBasicDetailsResError) {
        return;
      }
      if (getAttendanceBasicDetailsRes) {
        if (getAttendanceBasicDetailsRes?.data?.attendance_basic_details) {
          const {
            total_time,
            short_break_available_time,
            long_break_available_time,
            other_break_available_time,
            first_check_status,
            attendance_type,
            last_check_status,
            last_check_capture,
          } = getAttendanceBasicDetailsRes?.data?.attendance_basic_details;
          setTimer(total_time || '00:00:00');
          setSrtBrk(short_break_available_time || '00:00:00');
          setLngBrk(long_break_available_time || '00:00:00');
          setOtherBrk(other_break_available_time || '00:00:00');
          setFirstCheckStatus(first_check_status || '');
          setAtndType(attendance_type || 'attendance');
          setLastCaptureImage(last_check_capture || 'no_cam');
          setCheckInStatus(last_check_status == 'in' ? true : false);
        }
      }
    }
  }, [
    isGetAttendanceBasicDetailsPending,
    getAttendanceBasicDetailsRes,
    getAttendanceBasicDetailsResError,
  ]);

  useEffect(() => {
    getAttendanceDetails();
  }, []);
  const getAttendanceDetails = async () => {
    navigateToGetAttendanceBasicDetailsRequest({});
  };

  

  const isViewModalOpen = () => {
    setIsOpen(!isOpen);
  };

  const checkInBtn = async () => {
    const captureImage = await webcamRef.current.getScreenshot();
    console.log('ddddd', captureImage);

    if (!captureImage && isWebCamAvailable) {
      toast.warning('Please capture image');
      return;
    }
    setIsLoader(true);
    let location;
    navigator.geolocation.getCurrentPosition(function(position) {
      location = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };

      checkInOutFun(
        location,
        captureImage,
        attendanceType,
        otherbrTime,
        otherBreakReason,
      );
      setCaptureImage(isWebCamAvailable ? '' : appConstants.noCam);
    });
  };

  const getBtnOptions = () => {
    const data = { label: 'Check In', color: 'success' };
    if (checkInStatus && attendanceType === 'attendance') {
      data.label = 'Check Out';
      data.color = 'danger';
    }
    if (!checkInStatus && captureImage && attendanceType === 'attendance') {
      data.label = 'Check In';
      data.color = 'success';
    }
    if (checkInStatus && attendanceType === 'short_break') {
      data.label = 'Take short break';
      data.color = 'danger';
    }
    if (!checkInStatus && captureImage && attendanceType === 'short_break') {
      data.label = 'End short break';
      data.color = 'success';
    }
    if (checkInStatus && attendanceType === 'long_break') {
      data.label = 'Take long break';
      data.color = 'danger';
    }
    if (!checkInStatus && captureImage && attendanceType === 'long_break') {
      data.label = 'End long break';
      data.color = 'success';
    }
    if (checkInStatus && attendanceType === 'other_break') {
      data.label = 'Take other break';
      data.color = 'danger';
    }
    if (!checkInStatus && captureImage && attendanceType === 'other_break') {
      data.label = 'End other break';
      data.color = 'danger';
    }
    return data;
  };

  let attBtnOptions = getBtnOptions();

  useEffect(() => {
    if (checkInStatus == true) {
      var travelTime = timer;
      const intervalFun = setInterval(() => {
        travelTime = moment(travelTime, 'hh:mm:ss')
          .add(1, 'seconds')
          .format('HH:mm:ss');
        // var travelTime = timer.add(moment.duration(1, 'second'));
        setTimer(travelTime);
      }, 1000);
      return () => clearInterval(intervalFun);
    } else {
      if (atndType == 'short_break') {
        if (srtBrk === '00:00:00') {
          swal('Your short break time has been exausted');
        } else {
          // alert("in short break")
          var time1 = moment('00:15:00', 'HH:mm:ss');
          var time2 = moment(srtBrk, 'HH:mm:ss');
          // time2.isBefore(time1)=="true"?"00:15:00":time2
          //  var check=time1.isBefore(time2)

          // time1.isBefore(time2)==true?"00:15:00":srtBrk
          var travelTime = time1.isBefore(time2) == true ? '00:15:00' : srtBrk;

          const intervalFun = setInterval(() => {
            travelTime = moment(travelTime, 'hh:mm:ss')
              .subtract(1, 'seconds')
              .format('HH:mm:ss');
            if (travelTime === '00:00:00') {
              toast.warning(`Your Break has been finished`);

              clearInterval(intervalFun);
            }

            setTimer(travelTime);
          }, 1000);
          return () => clearInterval(intervalFun);
        }
      }
      if (atndType == 'long_break') {
        // alert('in long break');
        var travelTime = lngBrk;

        const intervalFun = setInterval(() => {
          travelTime = moment(travelTime, 'hh:mm:ss')
            .subtract(1, 'seconds')
            .format('HH:mm:ss');
          if (travelTime === '00:00:00') {
            toast.warning(`Your Break has been finished`);
            clearInterval(intervalFun);
          }
          setTimer(travelTime);
        }, 1000);
        return () => clearInterval(intervalFun);
      }
      if (atndType == 'other_break') {
        var travelTime = otherBrk;

        const intervalFun = setInterval(() => {
          travelTime = moment(travelTime, 'hh:mm:ss')
            .subtract(1, 'seconds')
            .format('HH:mm:ss');
          if (travelTime === '00:00:00') {
            toast.warning(`Your Break has been finished`);

            clearInterval(intervalFun);
          }

          setTimer(travelTime);
        }, 1000);
        return () => clearInterval(intervalFun);
      } else {
        console.warn('atndtype', atndType);
      }
    }
  }, [checkInStatus, atndType]);

  const isViewModalOpen1 = () => {
    setIsOpen(!isOpen);
    setIsOpen1(!isOpen1);
  };

  const checkInOutFun = async (
    location,
    captureImage,
    attendanceType,
    otherbrTime,
    otherBreakReason,
  ) => {
    var otherbr = `${otherbrTime}:00`;
    let data = {
      latitude: parseFloat(location.latitude),
      longitude: parseFloat(location.longitude),
      capture: captureImage || lastCaptureImage,
      status: 'status',
      attendance_check_status: checkInStatus ? 'out' : 'in',
      attendance_check_location: 'device',
      device: 'test',
      mac_address: 'test',
      attendance_type: attendanceType || atndType,
      other_break_time: otherbr,
      comment: otherBreakReason,
    };
    let result = await api.post(config.attendanceCheckInCheckOut, data);

    if (result && result.code == 200) {
      await sessionStorage.setItem('attendanceModal', 1);
      toast.success('Attendance Saved Successfully');
      setIsOpen1(!isOpen1);
      setIsLoader(false);
    } else {
      toast.warning('Something went wrong');
    }
  };

  return (
    <>
      {workingFrom === 'home' && permission !== 1 && (
        <CModal
          show={isOpen}
          onClose={() => isViewModalOpen(!isOpen)}
          size="md"
        >
          <CModalBody>
            <div className="row">
              <div>
                <div
                  className="cards section-gray"
                  style={{ BoxShadow: '0px 8px 8px 0px cornflowerblue' }}
                >
                  <div className="container mt-2">
                    <div className="row">
                      <div className="col-md-12">
                        <h3 style={{ color: '#6f4e37', align: 'center' }}>
                          {moment().format('HH') > 3 &&
                          moment().format('HH') < 12
                            ? 'Good Morning'
                            : moment().format('HH') > 12 &&
                              moment().format('HH') < 15
                            ? 'Good Afternoon'
                            : moment().format('HH') > 15 &&
                              moment().format('HH') < 20
                            ? 'Good Evening'
                            : moment().format('HH') > 20 &&
                              moment().format('HH') < 3
                            ? 'Good Night'
                            : 'Hi'}{' '}
                          {loggedUserInfo?.first_name +
                            ' ' +
                            loggedUserInfo?.last_name}
                          ,
                        </h3>
                        {/* <small>Mark Your attendance</small> */}
                        <div
                          className="card card-profile"
                          style={{
                            BoxShadow: '0px 8px 8px 0px cornflowerblue',
                          }}
                        >
                          <div className="card-avatar-top">
                            <a href="#">
                              {' '}
                              <img
                              style={{
                                height: '100px',
                                paddingTop: '10px',
                                paddingLeft: '7px',
                              }}
                              src={
                                lastCaptureImage &&
                                lastCaptureImage !== 'no_cam'
                                  ? lastCaptureImage
                                  : 'images/sgf_logo.jpg'
                              }
                              alt=""
                            />
                            </a>
                          </div>
                          {/* <div className="card-avatar-left">
                        <a href="#"> <img className="img" src="images/avatar2.png" /> </a>
                      </div> */}

                          <div className="table text-center">
                            {/* <h4 className="card-caption">Attendance Self Service</h4> */}
                            <div className="timeCounter">
                              {/* <h4 className="timeCount">{timer}</h4> */}
                              <div
                                className="text-center"
                                style={{ display: 'inline-block' }}
                              >
                                <div className="clock-1">
                                  <div className="hours">
                                    <div className="first">
                                      <div className="number">
                                        {timer && timer.length > 0 && timer[0]}
                                      </div>
                                    </div>
                                    <div className="second">
                                      <div className="number">
                                        {timer && timer.length > 0 && timer[1]}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="tick">:</div>
                                  <div className="minutes">
                                    <div className="first">
                                      <div className="number">
                                        {timer && timer.length > 0 && timer[3]}
                                      </div>
                                    </div>
                                    <div className="second">
                                      <div className="number">
                                        {timer && timer.length > 0 && timer[4]}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="tick">:</div>
                                  <div
                                    className={
                                      checkInStatus ? 'seconds move' : 'seconds'
                                    }
                                  >
                                    <div className="first">
                                      <div className="number">
                                        {timer && timer.length > 0 && timer[6]}
                                      </div>
                                    </div>
                                    <div className="second infinite">
                                      <div className="number">
                                        {timer && timer.length > 0 && timer[7]}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="text-center"
                              style={{ display: 'inline-block' }}
                            ></div>
                            <div className="ftr">
                              {!checkInStatus && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    isViewModalOpen1();
                                    setAttendanceType('attendance');
                                  }}
                                  className="check-in"
                                >
                                  Check In
                                </button>
                              )}
                              {checkInStatus && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    isViewModalOpen1();
                                    setAttendanceType('attendance');
                                  }}
                                  className="check-in-red"
                                >
                                  Check Out
                                </button>
                              )}
                            </div>
                          </div>
                          <div
                            className="text-center px-2"
                            style={{ paddingBottom: '10px' }}
                          >
                            <div className="row">
                              <div className="col-4">
                                {checkInStatus === true &&
                                  firstCheckStatus === 'in' && (
                                    <button
                                      className="button-78"
                                      data-toggle="tooltip"
                                      title="Short break for 15:00 min"
                                      onClick={() => {
                                        isViewModalOpen1();
                                        setAttendanceType('short_break');
                                      }}
                                    >
                                      Short Break
                                    </button>
                                  )}
                                {checkInStatus === false &&
                                  atndType === 'short_break' && (
                                    <button
                                      className="button-78 btn-profile-break"
                                      data-toggle="tooltip"
                                      title="Short break for 15:00 min"
                                      onClick={() => {
                                        isViewModalOpen1();
                                        setAttendanceType('short_break');
                                      }}
                                    >
                                      {' '}
                                      Closed Short Break
                                    </button>
                                  )}
                              </div>

                              <div className="col-4">
                                {checkInStatus === true &&
                                  firstCheckStatus === 'in' && (
                                    <button
                                      className="button-78 btn-profile-break"
                                      data-toggle="tooltip"
                                      title="Long break for 30:00 min"
                                      onClick={() => {
                                        isViewModalOpen1();
                                        setAttendanceType('long_break');
                                      }}
                                    >
                                      Long Break
                                    </button>
                                  )}
                                {checkInStatus === false &&
                                  atndType === 'long_break' && (
                                    <button
                                      className="button-78 btn-profile-break"
                                      data-toggle="tooltip"
                                      title="Long break for 30:00 min"
                                      onClick={() => {
                                        isViewModalOpen1();
                                        setAttendanceType('long_break');
                                      }}
                                    >
                                      Closed Long Break
                                    </button>
                                  )}
                              </div>
                              <div className="col-4">
                                {checkInStatus === true &&
                                  firstCheckStatus === 'in' && (
                                    <button
                                      className="button-78 btn-profile-break"
                                      data-toggle="collapse"
                                      data-target="#collapseExample"
                                      title="Apply other break"
                                      aria-expanded="false"
                                      aria-controls="collapseExample"
                                    >
                                      Other Break
                                    </button>
                                  )}{' '}
                                {checkInStatus === false &&
                                  atndType === 'other_break' && (
                                    <button
                                      className="button-78 btn-profile-break"
                                      data-toggle="tooltip"
                                      onClick={() => {
                                        isViewModalOpen1();
                                        setAttendanceType('other_break');
                                      }}
                                    >
                                      Closed other Break
                                    </button>
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="collapse" id="collapseExample">
                            <form>
                              <div className="form-group">
                                <label htmlFor="formGroupExampleInput">
                                  Time
                                </label>
                                <input
                                  type="time"
                                  className="form-control"
                                  id="formGroupExampleInput"
                                  name="breaktime"
                                  value={otherbrTime}
                                  placeholder="Example input"
                                  onChange={e => setOtherbrTime(e.target.value)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="formGroupExampleInput2">
                                  Reason for break
                                </label>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  id="formGroupExampleInput2"
                                  value={otherBreakReason}
                                  onChange={e =>
                                    setOtherBreakReason(e.target.value)
                                  }
                                  placeholder="Type Your Reason....."
                                  row="3"
                                />
                              </div>
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-bs-toggle="collapse"
                                data-target="#collapseExample"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                                onClick={() => {
                                  isViewModalOpen1();
                                  setAttendanceType('other_break');
                                }}
                              >
                                Break
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton color="success" onClick={() => isViewModalOpen(!isOpen)}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </CButton>{' '}
          </CModalFooter>
          {/* </div> */}
          <ToastContainer />
          {/* </div> */}
        </CModal>
      )}
      <CModal
        show={isOpen1}
        onClose={() => isViewModalOpen1(!isOpen1)}
        size="md"
      >
        {/* <CModalHeader closeButton>
                    <CModalTitle>View Details</CModalTitle>
                </CModalHeader> */}
        <CModalBody>
          {!isWebCamAvailable && (
            <div className="row">
              <div className="row text-center mt-3">
                <div className="col-md-12 mb-5">
                  <h3>Unable to detect your webcam</h3>
                  <span>
                    Your request will be sent to your reporting manager for
                    approval.
                  </span>
                </div>
                <div className="col-md-12">
                  <CButton color={attBtnOptions.color} onClick={checkInBtn}>
                    Click to {attBtnOptions.label}{' '}
                    {isLoader && (
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ textAlignLast: 'center' }}
                      ></i>
                    )}
                  </CButton>
                </div>
              </div>
            </div>
          )}
          {isWebCamAvailable && (
            <>
              <div className="row">
                {!captureImage && (
                  <div className="col-md">
                    <div className="text-center" style={{ margin: 'auto' }}>
                      <h5 style={{ color: '#6f4e37', align: 'center' }}>
                        CHECK IN
                      </h5>
                      {isOpen && (
                        <>
                          {isCamera == true ? (
                            <Webcam
                              audio={false}
                              height={250}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              width={300}
                              videoConstraints={videoConstraints}
                            />
                          ) : (
                            <div className="mt-5">
                              <img
                                src={'images/cameranotavailable.png'}
                                alt="User Image"
                                height="172"
                                width="280"
                              ></img>
                              <h3 className="mt-2">camera not available</h3>
                              <h5 className="mt-2">Login with your mobile</h5>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
                {captureImage && isCamera == true && (
                  <div className="col-md">
                    <div className="text-center" style={{ margin: 'auto' }}>
                      <h5 style={{ color: '#6f4e37', align: 'center' }}>
                        CAPTURE IMAGE
                      </h5>
                      <div className="mt-5">
                        <img
                          src={
                            captureImage && captureImage !== appConstants.noCam
                              ? captureImage
                              : 'images/capture.jpeg'
                          }
                          alt="User Image"
                          height="172"
                          width="280"
                        ></img>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="row text-center mt-3">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={captureImage ? () => setCaptureImage('') : capture}
                    style={{ align: 'center' }}
                  >
                    {!captureImage ? (
                      <i className="fa fa-camera" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-repeat" aria-hidden="true"></i>
                    )}
                  </button>
                  <CButton color={attBtnOptions.color} onClick={checkInBtn}>
                    {attBtnOptions.label} +"in"
                  </CButton>
                </div>
              </div>
            </>
          )}
        </CModalBody>
        <CModalFooter>
          <CButton color="success" onClick={() => isViewModalOpen1(!isOpen1)}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </CButton>{' '}
        </CModalFooter>
      </CModal>
      <ToastContainer />
    </>
  );
};

const mapStateToProps = state => {
  return {
    isGetAttendanceBasicDetailsPending: attendanceSelector.getIsAttendanceBasicDetailsPending(
      state,
    ),
    getAttendanceBasicDetailsRes: attendanceSelector.getAttendanceBasicDetailsRes(
      state,
    ),
    getAttendanceBasicDetailsResError: attendanceSelector.getAttendanceBasicDetailsResError(
      state,
    ),
  };
};
const mapDispatchToProps = dispatch => ({
  navigateToGetAttendanceBasicDetailsRequest: data =>
    dispatch(attendanceActions.getAttendanceBasicDetailsRequest(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CheckInCheckOut);
