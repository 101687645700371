import React, { useState } from 'react';
import {
  CModal,
  CModalBody,
  CModalTitle,
  CModalHeader,
  CModalFooter,
  CButton,
} from '@coreui/react';
import { convertDate } from 'src/utils/common';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import { toast } from 'react-toastify';
import { getLocalDataAsObject } from 'src/utils/CoustomStorage';
import Loader1 from 'src/views/common/loader1';


const ViewLeaveDateModal = ({
  viewLaeveModal,
  handleLeaveModal,
  leaveData,
  getLeaveForApproval,
}) => {
  const api = new API();
  const [leaveIds, setLeaveIds] = useState();
  const [reason, setReason] = useState('');
  const [isLoader1,setIsLoader1]=useState(false)
  // const [selectAll, setSelectAll] = useState(false);
  const handleLeaveIds = (e, item) => {
    let newArr = leaveIds && leaveIds.length > 0 ? [...leaveIds] : [];
    if (e.target.checked) {
      newArr.push({ id: item?.id });
      setLeaveIds(newArr);
    } else {
      let arr =
        newArr &&
        newArr.length > 0 &&
        newArr.filter(leave => leave?.id != item?.id);
      setLeaveIds(arr);
    }
  };
  const approveAndRejectLeave = async type => {
    setIsLoader1(true)
    if (reason) {
      let payload = {
        type: 'leave',
        is_approved: type === 'approve' ? 'approve' : 'reject',
        id: 1, //id is fixed not in used
        comment: reason,
        correction_data: JSON.stringify(leaveIds),
      };

      let result = await api.post(config.approveRejectAttendance, payload);
      if (result && result.code === 200) {
        setIsLoader1(false)
        handleLeaveModal();
        getLeaveForApproval();
        setReason('');
        setLeaveIds([]);
        toast(result && result?.message);
      } else {
        toast(result && result?.message);
      }
    } else {
      toast.warning('Please enter reason.');
    }
  };
  function allLeaveStatus(arr) {
    let status = '';
    let p = 0;
    let a = 0;
    let c = 0;
    for (let i = 0; i < arr.length; i++) {
      if(arr[i].is_cancel == 1){
        c++;
      }else if(arr[i].manager_id == getLocalDataAsObject('user')[0]?.id && arr[i].assign_manager == 1 && arr[i].is_approve_status == 0 && arr[i].is_rejected_status == 0) {
        p++;
      }else if(arr[i].manager_id == getLocalDataAsObject('user')[0]?.id && arr[i].assign_manager == 1 && (arr[i].is_approve_status == 1 || arr[i].is_rejected_status == 1)){
        a++;
      }else if (arr[i]?.assign_hr == 1 && arr[i].is_approve_status == 0 && arr[i].is_rejected_status == 0) {
        p++;
      }
      else {
        a++;
      }
    }
    if (arr.length === a) {
      return (status = 'Approved');
    }else if (arr.length === c) {
      return (status = 'Cancelled');
    }else {
      return (status = 'Pending');
    }
  }

  let status =
    leaveData &&
    (allLeaveStatus(leaveData && leaveData?.leaveDateArray) === 'Approved' || 
    allLeaveStatus(leaveData && leaveData?.leaveDateArray) === 'Cancelled')
      ? true
      : false;
  const closeModal = () => {
    handleLeaveModal();
    setReason('');
  };
  return (
    <div>
      <CModal
        show={viewLaeveModal}
        onClose={() => closeModal()}
        size="lg"
        style={{ marginTop: '70px' }}
      >
        <CModalHeader closeButton>
          <CModalTitle>Approve and Reject Leave(s)</CModalTitle>
        </CModalHeader>
        <CModalBody>
          {leaveData?.leaveDateArray.map((item, index) => (<>
          {(item.assigned_to ==  getLocalDataAsObject('user')[0]?.id || item.manager_id ==  getLocalDataAsObject('user')[0]?.id) && item?.assign_hr == 1 && item.assign_manager &&
            <div style={{display:"inline-flex",width:"100%"}}>
              <div style={{width:"50%"}}>
                <b style={{color:"brown"}}>Approval Status: </b><b style={{color:"green"}}>{item?.is_approve_status == 1 ? "Approved" : item?.is_rejected_status == 1 ? "Rejected" : ''}</b>
              </div>
              <div>
                <b style={{color:"brown"}}>Approval Comment : </b><b style={{color:"green"}}>{item?.is_approve_status == 1 ? item?.approval_comment : item?.is_rejected_status == 1 ? item?.rejection_comment : ''}</b>
              </div>
                </div>}
          </>))}
            <div>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Leave Date</th>
                  <th scope="col">Leave Type</th>
                  <th scope="col">Leave Status</th>
                  <th scope="col">
                    {/* <input
                  type="checkbox"
                  onChange={() => setSelectAll(!selectAll)}
                ></input> */}
                  </th>
                </tr>
              </thead>
              <tbody>
                {leaveData?.leaveDateArray.map((item, index) => (
                  <tr key={item?.id}>
                    <th scope="row">{index + 1}</th>
                    <th>{leaveData?.employee_name}</th>
                    <td>{convertDate(item?.leave_date, 'DD-MM-YYYY')}</td>
                    <td>{item?.leave_type}</td>
                    <td>{item?.leave_status}</td>
                    <td>
                      {((item.assigned_to == getLocalDataAsObject('user')[0]?.id && item.assign_hr == 1 && item?.is_approve_status == 1) || (item.manager_id == getLocalDataAsObject('user')[0]?.id && item.assign_manager == 1 && item?.is_approve_manager == 1)) ? (
                        <i
                          className="fa fa-check"
                          style={{ color: 'green' }}
                          aria-hidden="true"
                        ></i>
                      ) : ((item.assigned_to == getLocalDataAsObject('user')[0]?.id && item.assign_hr == 1 && item?.is_rejected_status == 1) || (item.manager_id == getLocalDataAsObject('user')[0]?.id && item.assign_manager == 1 && item?.is_reject_manager == 1)) ? (
                        <i
                          className="fa fa-times"
                          style={{ color: 'red' }}
                          aria-hidden="true"
                        ></i>
                      ) :  ((item.assigned_to == getLocalDataAsObject('user')[0]?.id && item.assign_hr == 1 && item?.is_cancel == 1) || (item.manager_id == getLocalDataAsObject('user')[0]?.id && item.assign_manager == 1 && item?.is_cancel == 1)) ? (
                        <b
                          style={{ color: '#19789e' }}
                        >Cancelled</b>
                      ) :
                      
                      (
                        <input
                          type="checkbox"
                          onChange={e => handleLeaveIds(e, item)}
                          // checked={selectAll}
                        ></input>
                      )}
                    </td>
                  </tr>
              ))}
              </tbody>
            </table>
            <form>
              <div className="form-group">
                <label for="exampleFormControlTextarea1">Comment</label>
                <textarea
                  onChange={e => setReason(e.target.value)}
                  disabled={status}
                  value={reason}
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </div>
            </form>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton
            disabled={status}
            className="btn btn-primary"
            onClick={() => approveAndRejectLeave('approve')}
          >
            Approve
          </CButton>
          <CButton
            disabled={status}
            onClick={() => approveAndRejectLeave('reject')}
            className="btn btn-danger"
          >
            Reject
          </CButton>
         {isLoader1 && <Loader1></Loader1>}
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default ViewLeaveDateModal;
