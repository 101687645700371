
import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalHeader,
    CModalTitle,

} from '@coreui/react'
import { config } from 'src/utils/apiUrl'
import API from 'src/utils/apiCalling'

import 'react-toastify/dist/ReactToastify.css';

const NewJoiningModal = (props) => {
    const api = new API()
    const { isOpen, isViewModalOpen, setIsOpenNewJoiningModal, newJoiningData } = props

    return (
        <>
            <CModal
                show={isOpen}
                onClose={() => !isOpen}
            // size="lg"
            >
                <CModalHeader closeButton>
                    <CModalTitle>Let's Welcome on Board</CModalTitle>
                </CModalHeader>
                <CModalBody style={{ padding: "0px 0px 0px 0px", maxHeight: "500px", overflowY: "auto" }} closeButton>
                    <>
                       {newJoiningData && 
                       newJoiningData.length >0
                       && newJoiningData.map((item,index)=>
                       <div className=' mt-3 mb-3 m-2' key={index} style={{ boxShadow: "rgba(2, 2, 2, 0.1) 0px 0px 1px 2px", border: "1px" }}>
                       <div class="d-flex col-md-12 p-0" style={{backgroundImage: 'url(images/createNewMrf-bg.svg)',backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
                           <div className='col-md-2'>
                               <div class=" float-left">
                                   <img
                                       style={{marginTop:"10px"}}
                                       src="images/att-icons2/welcome-back.png" alt=""
                                        />
                               </div>
                           </div>
                           <div className='col-md-10'>
                               <p style={{ fontSize: "15px", paddingTop: "10px",fontWeight:"bold" }}>{item?.description}</p>
                           </div>
                       </div>

                   </div>
                    )}
                  

                    </>
                </CModalBody>
            </CModal>
        </>

    )
}
export default NewJoiningModal







