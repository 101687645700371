const textTypes = ['doc', 'docx', 'odt', 'rtf', 'txt'];
const audioTypes = ['mp3', 'wav', 'ogg', 'webm', 'm4a'];
const videoTypes = ['avi', 'mov', 'mp4', 'mpg', 'wmv'];
const thumbTypes = ['mov', 'mp4', 'wmv'];
const imageTypes = ['bmp', 'gif', 'jpg', 'png', 'svg', 'jpeg'];
const pageLayoutTypes = ['pdf'];
const spreadSheetTypes = ['xls', 'xlsx'];
const compresedFileTypes = ['7z', 'zip', 'rar', 'tar', 'gz'];
const dataFileTypes = ['csv', 'ppt', 'pptx'];
const textPreviewTypes = ['csv', 'txt', 'log'];
const officePreviewTypes = [
  'doc',
  'docx',
  'ppt',
  'pptx',
  'odt',
  'ods',
  'odp',
  ...spreadSheetTypes,
];
const otherFilesTypes = [
  'apk',
  'ipa',
  'exe',
  'mpeg',
  'wma',
  'flv',
  'm4a',
  'mkv',
  'psd',
];

const googlePreviewTypes = ['rtf'];

const appConstants = {
  version: '1.0.0',
  toastOptions: {
    autoClose: 5000, // miliseconds
    position: 'top-right',
  },
  noCam: 'no_cam',
  groupTypes: ['group', 'broadcast'],
  fileTypes: {
    textTypes,
    audioTypes,
    videoTypes,
    thumbTypes,
    imageTypes,
    pageLayoutTypes,
    spreadSheetTypes,
    compresedFileTypes,
    dataFileTypes,
    textPreviewTypes,
    officePreviewTypes,
    googlePreviewTypes,
    otherFilesTypes,
  },
  // attendanceStatus: {
  //   first_half_present: { short_name: 'FHP', txt_color_1: '#e55353' },
  //   second_half_present: { short_name: 'SHP', txt_color_1: '#e55353' },
  //   Sunday_Off: { short_name: 'WO', txt_color_1: '#5c5959' },
  //   Second_Saturday_Off: { short_name: 'WO', txt_color_1: '#5c5959' },
  //   Forth_Saturday_Off: { short_name: 'WO', txt_color_1: '#5c5959' },
  //   Fourth_Saturday_Off: { short_name: 'WO', txt_color_1: '#5c5959' },
  //   present: { short_name: 'PR', txt_color_1: '#2eb85c'},
  //   PR: { short_name: 'PR', txt_color_1: '#2eb85c'},
  //   AB: { short_name: 'AB', txt_color_1: '#e55353' },
  //   PH: { short_name: 'PH', txt_color_1: '#2b7b8a' },
  //   PH_: { short_name: 'PH-P', txt_color_1: '#2b7b8a' },
  //   PH_FHP: { short_name: 'PH-FHP', txt_color_1: '#2b7b8a' },
  //   PH_SHP: { short_name: 'PH-SHP', txt_color_1: '#2b7b8a' },
  //   FHP: { short_name: 'FHP', txt_color_1: '#e55353' },
  //   SHP: { short_name: 'SHP', txt_color_1: '#e55353' },
  //   WO: { short_name: 'WO', txt_color_1: '#5c5959' },
  //   WO_P: { short_name: 'WO-P', txt_color_1: '#5c5959' },
  //   WO_FHP: { short_name: 'WO-FHP', txt_color_1: '#5c5959' },
  //   WO_SHP: { short_name: 'WO-SHP', txt_color_1: '#5c5959' },
  //   PL: { short_name: 'PL', txt_color_1: '#0b429c' },
  //   LW: { short_name: 'LW', txt_color_1: '#f79205' },
  //   CO: { short_name: 'CO', txt_color_1: '#fc8403' },
  //   TOD:{ short_name: 'TOD', txt_color_1: '#fc8403' },
  //   C_Off:{ short_name: 'C-off', txt_color_1: '#fc8403' },
  //   SSL:{ short_name: 'SSL', txt_color_1: '#fc8403' },
  //   FH_PL:{ short_name: 'FH-PL', txt_color_1: '#0b429c' },
  //   SH_PL:{ short_name: 'SH-PL', txt_color_1: '#0b429c' },
  // },
  
  attendanceStatus: {
    first_half_present: { short_name: 'FHP', full_name: 'First Half Present', is_show_to_user: 0, txt_color_1: '#e55353'  ,total:0,addition:1},
    second_half_present: { short_name: 'SHP', full_name: 'Second Half Present', is_show_to_user: 0, txt_color_1: '#e55353'  ,total:0,addition:1},
    // Sunday_Off: { short_name: 'WO', full_name: 'Sunday Off', is_show_to_user: 0, txt_color_1: '#5c5959'  ,total:0,addition:4},
    // Second_Saturday_Off: { short_name: 'Week Off', full_name: 'Second Saturday Off', is_show_to_user: 0, txt_color_1: '#5c5959'  ,total:0,addition:1},
    // Forth_Saturday_Off: { short_name: 'Week Off', full_name: 'Forth Saturday Off', is_show_to_user: 0, txt_color_1: '#5c5959'  ,total:0,addition:1},
    // Forth_Saturday_Off: { short_name: 'Week Off', full_name: 'Forth Saturday Off', is_show_to_user: 0, txt_color_1: '#5c5959'  ,total:0,addition:1},
    // present: { short_name: 'P', full_name: 'Present', is_show_to_user: 0, txt_color_1: '#2eb85c' ,total:0,addition:1},
    PR: { short_name: 'P', full_name: 'Present', is_show_to_user:1, txt_color_1: '#2eb85c' ,total:0,addition:1},
    AB: { short_name: 'A', full_name: 'Absent', is_show_to_user: 1, txt_color_1: '#e55353'  ,total:0,addition:1},
    PH: { short_name: 'H', full_name: 'Holiday', is_show_to_user: 1, txt_color_1: '#2b7b8a'  ,total:0,addition:1},
    PH_P: { short_name: 'H-P', full_name: ' Holiday-P', is_show_to_user: 0, txt_color_1: '#2b7b8a'  ,total:0,addition:1},
    PH_FHP: { short_name: 'H-FHP', full_name: 'Holiday-First Half Present', is_show_to_user: 0, txt_color_1: '#2b7b8a'  ,total:0,addition:1},
    PH_SHP: { short_name: 'H-SHP', full_name: 'Holiday-Second Half Present', is_show_to_user: 0, txt_color_1: '#2b7b8a'  ,total:0,addition:1},
    FHP: { short_name: 'FHP', full_name: 'First Half Present', is_show_to_user: 0, txt_color_1: '#e55353'  ,total:0,addition:1},
    SHP: { short_name: 'SHP', full_name: 'Second Half Present', is_show_to_user: 0, txt_color_1: '#e55353'  ,total:0,addition:1},
    WO: { short_name: 'O', full_name: 'Off', is_show_to_user: 1, txt_color_1: '#5c5959'  ,total:0,addition:1},
    WO_P: { short_name: 'WO-P', full_name: 'Week Off-P', is_show_to_user: 0, txt_color_1: '#5c5959'  ,total:0,addition:1},
    WO_FHP: { short_name: 'WO-FHP', full_name: 'Week Off-First Half Present', is_show_to_user: 0, txt_color_1: '#5c5959' ,total:0,addition:1},
    WO_SHP: { short_name: 'WO-SHP', full_name: 'Week Off-Second Half Present', is_show_to_user: 0, txt_color_1: '#5c5959' ,total:0,addition:1},
    PL: { short_name: 'EL', full_name: 'Earn Leave', is_show_to_user: 1, txt_color_1: '#0b429c' ,total:0,addition:1},
    LW: { short_name: 'LW', full_name: 'Leave Without Pay', is_show_to_user: 1, txt_color_1: '#f79205',total:0,addition:1},
    CO: { short_name: 'CO', full_name: 'CO', is_show_to_user: 0, txt_color_1: '#fc8403' ,total:0,addition:1},
    TOD:{ short_name: 'OD', full_name: 'Off Duty', is_show_to_user: 1, txt_color_1: '#fc8403',total:0,addition:1 },
    C_Off:{ short_name: 'CO', full_name: 'Comp-off', is_show_to_user: 1, txt_color_1: '#fc8403' ,total:0,addition:1},
    FH_CO:{ short_name: 'FH_CO', full_name: ' First Half Comp-off', is_show_to_user: 0, txt_color_1: '#fc8403' ,total:0,addition:1},
    SH_CO:{ short_name: 'SH_CO', full_name: ' Second Half Comp-off', is_show_to_user: 0, txt_color_1: '#fc8403' ,total:0,addition:1},
    SSL:{ short_name: 'SSL', full_name: 'Short Leave', is_show_to_user: 0, txt_color_1: '#fc8403' ,total:0,addition:0.25},
    FH_PL:{ short_name: 'FH-EL', full_name: 'First Half-Earn Leave', is_show_to_user: 0, txt_color_1: '#5c5959' ,total:0,addition:1},
    SH_PL:{ short_name: 'SH-EL', full_name: 'Second Half-Earn Leave', is_show_to_user: 0, txt_color_1: '#5c5959' ,total:0,addition:1},
    OT:{ short_name: 'OT', full_name: 'Over Time', is_show_to_user: 0, txt_color_1: '#fc8403' ,total:0,addition:1},
    CL:{ short_name: 'CL', full_name: 'Casual Leave', is_show_to_user: 0, txt_color_1: '#fc8403' ,total:0,addition:1},
    SL:{ short_name: 'SL', full_name: 'Sick Leave', is_show_to_user: 0, txt_color_1: '#0b429c' ,total:0,addition:1},
    EL:{ short_name: 'EL', full_name: 'Earned Leave', is_show_to_user: 0, txt_color_1: '#5c5959' ,total:0,addition:1},
    FH_SSL:{ short_name: 'FH_SSL', full_name: 'First Half Short Leave', is_show_to_user: 0, txt_color_1: '#5c5959' ,total:0,addition:1},
    SH_SSL:{ short_name: 'SH_SSL', full_name: 'Second Half Short Leave', is_show_to_user: 0, txt_color_1: '#fc8403' ,total:0,addition:1},
    FH_CL:{ short_name: 'FH_CL', full_name: 'Casual Leave', is_show_to_user: 0, txt_color_1: '#fc8403' ,total:0,addition:1},
    SH_CL:{ short_name: 'SH_CL', full_name: 'Casual Leave', is_show_to_user: 0, txt_color_1: '#fc8403' ,total:0,addition:1},
    FH_SL:{ short_name: 'FH_SL', full_name: 'Sick Leave', is_show_to_user: 0, txt_color_1: '#0b429c' ,total:0,addition:1},
    SH_SL:{ short_name: 'SH_SL', full_name: 'Sick Leave', is_show_to_user: 0, txt_color_1: '#0b429c' ,total:0,addition:1},
    FH_EL:{ short_name: 'FH_EL', full_name: 'Earned Leave', is_show_to_user: 0, txt_color_1: '#5c5959' ,total:0,addition:1},
    SH_EL:{ short_name: 'SH_EL', full_name: 'Earned Leave', is_show_to_user: 0, txt_color_1: '#5c5959' ,total:0,addition:1},
    FH_LW: { short_name: 'FH_LW', full_name: 'Leave Without Pay', is_show_to_user: 1, txt_color_1: '#f79205',total:0,addition:1},
    SH_LW: { short_name: 'SH_LW', full_name: 'Leave Without Pay', is_show_to_user: 1, txt_color_1: '#f79205',total:0,addition:1},
  },
  PG_location: [
    { id: 1, label: 'Head Office', value: 'Head Office'},
    { id: 2, label: 'Jewar', value: 'Jewar' },
  ],
  validations: {
    minLength: 'Min $1 character required',
    maxLength: 'Max $1 character required',
    allFieldReq: 'All fields are required',
    fieldReq: '$FIELD is required',
    emailReq: 'Email id is required',
    emailInvalid: 'Email address invalid',
    inavlidInOutTime: 'Check In should be less than Check Out time',
  },
  accountStatus: [
    { id: 1, label: 'Active', value: 'active'},
    { id: 2, label: 'Inactive', value: 'inactive' },
  ],
  
  pageLimit:10,
  company:{
    name:"SGF private limited",
    logo:'images/sgf_logo.jpg'
  }
  
};
export default appConstants;
